import React from "react";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";
import "./impact.css";

const newsArticles = [
    {
        title: "Dr. Grace Parraga receives the King Charles III Coronation Medal for outstanding contributions to lung health in Canada",
        date: "February 2025",
        link: "https://www.lung.ca/news/latest-news/king-charles-iii-coronation-medal-recipients"
    },
    {
        title: "Recognizing exceptional service and contributions",
        date: "November 2023",
        link: "https://www.schulich.uwo.ca/about/news/2023/november/recognizing_exceptional_service_and_contributions.html"
    },   
    {
        title: "Chest CT findings 1 year after COVID-19: another piece of the post-pandemic puzzle",
        date: "July 5, 2023",
        link: "https://pubs.rsna.org/doi/10.1148/radiol.231502"
    },
    {
        title: "What a Ten-Year CT Study Reveals About Emphysema and Continued Smoking",
        date: "April 13, 2023",
        link: "https://www.diagnosticimaging.com/view/ten-year-ct-study-emphysema-and-continued-smoking"
    },
    {
      title: "CBC Montreal article mentions Western University's long-COVID study led by Dr. Parraga",
      date: "July 16, 2022",
      link: "https://www.cbc.ca/news/canada/montreal/long-covid-study-montreal-1.6521131"
    },	
    {
      title: "Le Devoir article on long-COVID study, led by Dr. Parraga",
      date: "July 16, 2022",
      link: "https://www.ledevoir.com/societe/sante/734583/coronavirus-les-canadiens-souffrant-de-covid-longue-duree-pourront-ils-opter-pour-l-aide-medicale-a-mourir"
    },
    {
        title: "Globe and Mail article - what is long-COVID",
        date: "July 13, 2022",
        link: "https://www.theglobeandmail.com/canada/article-what-is-long-covid-symptoms-causes-explained/"
    },
    {
        title: "The Toronto Star Podcast interview with Dr. Grace Parraga on her key discovery for long-COVID and its potential treatments",
        date: "July 6, 2022",
        link: "https://www.thestar.com/podcasts/thismatters/2022/07/06/can-an-ontario-breakthrough-help-long-covid-sufferers.htm"
    },
    {
        title: "TVO.org speaks with professor Grace Parraga about her team's new study and what it could mean for possible future treatments",
        date: "July 5, 2022",
        link: "https://www.tvo.org/article/its-not-in-your-head-its-in-your-lungs-a-researcher-on-her-long-covid-findings"
    },
    {
        title: "New Imaging Technique Starkly Reveals The Source of Long COVID in The Lungs",
        date: "June 30, 2022",
        link: "https://www.sciencealert.com/new-imaging-techniques-starkly-reveals-what-long-covid-lungs-can-look-like"
    },
    {
        title: "Aunt Minnie's web site covers the long-term effects of COVID - research study led by Dr. Grace Parraga",
        date: "June 29, 2022",
        link: "https://www.auntminnie.com/clinical-news/mri/article/15631083/new-mri-technique-shows-lung-problems-in-long-covid-patients"
    },
    {
        title: "Burnaby Now covers in details the long-COVID study with lead investigator Dr. Grace Parraga",
        date: "June 29, 2022",
        link: "https://www.burnabynow.com/highlights/canadian-researchers-reveal-hope-filled-discovery-for-long-covid-patients-5532322"
    },
    {
        title: "London News Today: Canadian researchers reveal hope-filled discovery for long COVID patients",
        date: "June 29, 2022",
        link: "https://blackburnnews.com/london/london-news/2022/06/29/western-researchers-use-mri-learn-cause-long-covid-symptoms/"
    },
    {
        title: "North Shore News: Western researchers use MRI to learn cause of long-COVID symptoms",
        date: "June 29, 2022",
        link: "https://www.nsnews.com/highlights/canadian-researchers-reveal-hope-filled-discovery-for-long-covid-patients-5532322"
    },
    {
        title: "Global National (broadcast): Ontario researchers, led by Dr. Grace Parraga, focus on possible clue of long-COVID",
        date: "June 28, 2022",
        link: "https://globalnews.ca/video/8954880/ontario-researchers-focus-on-possible-clue-of-long-covid/"
    },
    {
        title: "London Free Press: Microscopic lung abnormality may play role in long-COVID: study led by Dr. Grace Parraga - Republished in the Toronto Sun, Ottawa Sun, Calgary Sun, and other regional postmedia publications.",
        date: "June 28, 2022",
        link: "https://lfpress.com/news/local-news/microscopic-lung-abnormality-may-play-role-in-long-covid-stud"
    },
    {
        title: "CBC London: Cause of long-COVID symptoms revealed by lung-imaging research at Western University",
        date: "June 28, 2022",
        link: "https://www.cbc.ca/news/canada/london/cause-of-long-covid-symptoms-revealed-by-lung-imaging-research-at-western-university-1.6504318"
    },
    {
        title: "CBC Afternoon Drive: Research into long-COVID by Western University. The story rebroadcasted on CBC London Morning",
        date: "June 28, 2022",
        link: "https://www.cbc.ca/listen/live-radio/1-80-afternoon-drive/clip/15922029-research-into-long-covid-lung-impact-by-western"
    },
    {
        title: "CTV News London: Western study using lung imaging technology reveals cause of long-COVID symptoms. The story also featured on the CTV National Health page and tweeted out by CTV National News",
        date: "June 28, 2022",
        link: "https://london.ctvnews.ca/lung-imaging-technology-reveals-cause-of-long-covid-symptoms-western-study-1.5966717"
    },
    {
        title: "Western News provides detailed information on long-COVID research from Dr. Grace Parraga's APILab group",
        date: "June 28, 2022",
        link: "https://news.westernu.ca/2022/06/innovative-lung-imaging-technique-shows-cause-of-long-covid-symptoms"
    },
    {
        title: "Global News Health covers the key long-COVID findings from Dr. Grace Parraga's research at Western Universit",
        date: "June 28, 2022",
        link: "https://globalnews.ca/news/8950820/long-covid-canadian-researchers-causes-study/"
    },
    {
        title: "Fumin Guo is the winner of the Polanyi Prize in Physiology and Medicine for 2021",
        date: "January 18, 2022",
        link: "https://ontariosuniversities.ca/impact/award-winners/john-charles-polanyi-prizes-2021"
    },
    {
        title: "Rachel Eddy is the Respiratory Structure and Function Early Career Professionals winner as seen in ATS 2021 Highlights",
        date: "July 13, 2021",
        link: "https://www.thoracic.org/members/assemblies/assemblies/rsf/ecp-highlights/resources/rsf-ecp-highlight-rach-eddy.pdf"
    },
    {
        title: "Western prof studies long-term effects of COVID-19 on lungs",
        date: "October 6, 2020",
        link: "https://westerngazette.ca/news/western-prof-studies-long-term-effects-of-covid-19-on-lungs/article_b87d2d1a-0266-11eb-9300-77cdc00a734a.html"
    },
    {
        title: "Vaping Contrast",
        date: "2020",
        link: "https://www.schulich.uwo.ca/rapport/2020/robarts_discovery/grace_parraga.html"
    },
    {
        title: "Funding for research on long-term lung effects of COVID-19",
        date: "September 10, 2020",
        link: "https://news.westernu.ca/2020/09/covid19-longterm-effects-lungs/"
    },
    {
        title: "New Research Projects Aim to Improve Ontario's Response to COVID-19",
        date: "September 10, 2020",
        link: "https://news.ontario.ca/en/backgrounder/58332/new-research-projects-aim-to-improve-ontarios-response-to-covid-1"
    },
    {
        title: "From the frontlines of Italy's COVID-19 fight",
        date: "March 24, 2020",
        link: "https://www.schulich.uwo.ca/about/news/2020/march/feature_from_the_frontlines_of_italys_covid19_fight.html"
    },    
    {
        title: "Doctor issues dispatches from Italian front",
        date: "March 26, 2020",
        link: "https://news.westernu.ca/2020/03/doctor-issues-dispatches-from-the-italy-front/"
    },    
    {
        title: "London researchers holding first-of-its-kind forum on vaping risks for teens",
        date: "February 23, 2020",
        link: "https://www.thesudburystar.com/news/local-news/london-researchers-hold-forum-on-vaping-risks-for-teens/wcm/9a993505-d99b-4511-8f87-7bdb6d26c772"
    },    
    {
        title: "Biomedical Picture of The Day",
        date: "January 15, 2020",
        link: "https://bpod.org.uk/archive/2020/1/15"
    },    
    {
        title: "CHEST Today",
        date: "January 3, 2020",
        link: "http://mailview.bulletinhealthcare.com/mailview.aspx?m=2020010301chest&r=9467129-d421&render=y"
    },    
    {
        title: "Western News, Western University",
        date: "December 16, 2019",
        link: "https://news.westernu.ca/2019/12/newsmakers-2019-grace-parraga/"
    },    
    {
        title: "Medisite",
        date: "December 5, 2019",
        link: "https://www.medisite.fr/maladies-orl-et-respiratoires-asthme-les-anomalies-dans-les-poumons-seraient-hereditaires-selon-une-etude.5547182.196464.html&__twitter_impression=tru"
    },   
    {
        title: "New study could be game changer for asthma sufferers",
        date: "December 4, 2019",
        link: "https://london.ctvnews.ca/new-study-could-be-game-changer-for-asthma-sufferers-1.4714955"
    },          
    {
        title: "Western University imaging studies challenge assumptions about asthma",
        date: "December 4, 2019",
        link: "https://lfpress.com/news/local-news/western-university-lung-imaging-studies-challenge-assumptions-about-asthma"
    },          
    {
        title: "Lung images of twins with asthma add to understanding of the disease",
        date: "December 4, 2019",
        link: "https://mediarelations.uwo.ca/2019/12/04/lung-images-of-twins-with-asthma-add-to-understanding-of-the-disease/"
    },          
    {
        title: "Vaping: imagine inhaling a pound of butter",
        date: "September 30, 2019",
        link: "https://www.winnipegfreepress.com/opinion/analysis/vaping-imagine-inhaling-a-pound-of-butter-561744432.html"
    },          
    {
        title: "Grace Parraga: Vaping is having a deadly impact on people's lungs",
        date: "September 21, 2019",
        link: "https://www.thetelegram.com/lifestyles/health/grace-parraga-vaping-is-having-a-deadly-impact-on-peoples-lungs-355190/"
    },          
    {
        title: "Vaping is having a deadly impact on people's lungs",
        date: "September 21, 2019",
        link: "https://www.theguardian.pe.ca/lifestyles/health/grace-parraga-vaping-is-having-a-deadly-impact-on-peoples-lungs-355190"
    },          
    {
        title: "As vaping-related illnesses hit Canada, a lung expert says we need to rethink e-cigarettes",
        date: "September 21, 2019",
        link: "https://www.cbc.ca/radio/day6/trudeau-s-blackface-vaping-related-illness-irrational-voters-rambo-tourism-roy-cohn-and-more-1.5288549/as-vaping-related-illnesses-hit-canada-a-lung-expert-says-we-need-to-rethink-e-cigarettes-1.5288559"
    },          
    {
        title: "Un jeune Ontarien victime du vapotage",
        date: "September 19, 2019",
        link: "https://www.ledevoir.com/societe/sante/562932/un-premier-cas-de-maladie-pulmonaire-lie-au-vapotage-identifie-au-canada"
    },          
    {
        title: "As an imaging scientist, I can see that vaping is like cigarettes all over again",
        date: "September 18, 2019",
        link: "https://www.ausdoc.com.au/opinion/imaging-scientist-i-can-see-vaping-cigarettes-all-over-again"
    },          
    {
        title: "Vapotage: des effets dévastateurs à craindre pour les poumons",
        date: "September 18, 2019",
        link: "https://theconversation.com/vapotage-des-effets-devastateurs-a-craindre-pour-les-poumons-123633.htm"
    },          
    {
        title: "Vaping: As an imaging scientist I fear the deadly impact on people’s lungs",
        date: "September 15, 2019",
        link: "https://theconversation.com/vaping-as-an-imaging-scientist-i-fear-the-deadly-impact-on-peoples-lungs-123435.htm"
    },          
    {
        title: "The Many Sides of the Cannabis Conversation",
        date: "2019",
        link: "https://www.schulich.uwo.ca/rapport/2019/features/the_many_sides_of_the_cannabis_conversation.html"
    },          
    // {
    //     title: "",
    //     date: "",
    //     link: ""
    // },          

    // Add more articles as needed
];

const Impact = () => {
    return (
        <div id="everything">
            <NavigationBar />
            <div class="impactvideo-container">
				<div class="col">
					<iframe 
                        title="Chronic Lung Disease" 
                        width="100%"
                        src="https://www.youtube.com/embed/3rDbvTsYmzw" 
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen>
                    </iframe>
				</div>
				<div class="col">
					<iframe 
                        title="Profiles of Excellence" 
                        width="100%"
                        src="https://www.youtube.com/embed/gP9q_0yUKMQ" 
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen>
                    </iframe>
				</div>
			</div>
            <div className='text-center text-black py-2 justify-center text-xl md:text-3xl lg:text-4xl xl:text-5xl'>
                Media Coverage
            </div>
            <div className="articles">
                {newsArticles.map((article, index) => (
                <div className="article" key={index}>
                    <div className="text-black p-1 text-md md:text-lg lg:text-xl xl:text-2xl">
                        <a href={article.link} target="_blank" rel="noopener noreferrer">{article.title}</a>
                    </div>
                    <div className="text-black font-bold p-1 txt-md md:text-lg lg:text-xl xl:text-2xl">
                        {article.date}
                    </div>
                </div>
                ))}
            </div>
            <br></br>            
            <Footer />
        </div>
    ) 
};

export default Impact